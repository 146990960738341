var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"contentALL"},[_c('el-form',{ref:"ruleForm",staticClass:"demo-ruleForm",attrs:{"model":_vm.ruleForm,"rules":_vm.rules,"label-width":"auto"}},[_c('div',{staticClass:"flex"},[_c('el-form-item',{class:(_vm.ruleForm.classes != 5 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 1) ||
            (_vm.ruleForm.classes == 10 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 4) ||
            (_vm.ruleForm.classes == 9 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 3) ||
            (_vm.ruleForm.classes == 6 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 2)
              ? ''
              : 'forTiem-L',attrs:{"label":"企业类型","prop":"type"}},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":_vm.regionChange},model:{value:(_vm.ruleForm.type),callback:function ($$v) {_vm.$set(_vm.ruleForm, "type", $$v)},expression:"ruleForm.type"}},_vm._l((_vm.options),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{class:(_vm.ruleForm.classes != 5 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 1) ||
            (_vm.ruleForm.classes == 10 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 4) ||
            (_vm.ruleForm.classes == 9 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 3) ||
            (_vm.ruleForm.classes == 6 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 2)
              ? ''
              : 'forTiem-R',attrs:{"label":"企业类别","prop":"classes"}},[_c('el-select',{attrs:{"placeholder":"请选择"},on:{"change":_vm.classesChange},model:{value:(_vm.ruleForm.classes),callback:function ($$v) {_vm.$set(_vm.ruleForm, "classes", $$v)},expression:"ruleForm.classes"}},_vm._l((_vm.categoryOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('div')],1),_c('div',{staticClass:"flex"},[_c('el-form-item',{class:(_vm.ruleForm.classes != 5 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 1) ||
            (_vm.ruleForm.classes == 10 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 4) ||
            (_vm.ruleForm.classes == 9 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 3) ||
            (_vm.ruleForm.classes == 6 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 2)
              ? ''
              : 'forTiem-L',attrs:{"label":"企业性质","prop":"nature"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.ruleForm.nature),callback:function ($$v) {_vm.$set(_vm.ruleForm, "nature", $$v)},expression:"ruleForm.nature"}},_vm._l((_vm.natureOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),(
            _vm.ruleForm.classes != 5 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 1
          )?_c('el-form-item',{attrs:{"label":"电厂名称(全称)","prop":"name"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1):_vm._e(),(
            _vm.ruleForm.classes == 6 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 2
          )?_c('el-form-item',{attrs:{"label":"矿山名称(全称)","prop":"name"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1):_vm._e(),(
            _vm.ruleForm.classes == 10 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 4
          )?_c('el-form-item',{attrs:{"label":"企业名称(全称)","prop":"name"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.ruleForm.name),callback:function ($$v) {_vm.$set(_vm.ruleForm, "name", $$v)},expression:"ruleForm.name"}})],1):_vm._e(),(
            _vm.ruleForm.classes == 9 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 3
          )?_c('el-form-item',{attrs:{"label":"所属省市区","prop":"cityId"}},[_c('el-cascader',{attrs:{"options":_vm.citys,"placeholder":"请选择所属省市区","props":{
              value: 'id',
              label: 'name',
            }},on:{"change":_vm.cityIdChange},model:{value:(_vm.ruleForm.cityId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "cityId", $$v)},expression:"ruleForm.cityId"}})],1):_vm._e()],1),(
          (_vm.ruleForm.classes == 10 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 4) ||
          (_vm.ruleForm.classes == 6 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 2)
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"所属省市区","prop":"cityId"}},[_c('el-cascader',{attrs:{"options":_vm.citys,"placeholder":"请选择所属省市区","props":{
              value: 'id',
              label: 'name',
            }},on:{"change":_vm.cityIdChange},model:{value:(_vm.ruleForm.cityId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "cityId", $$v)},expression:"ruleForm.cityId"}})],1),_c('el-form-item',{attrs:{"label":"是否在产煤矿","prop":"whetherProductCoal"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.ruleForm.whetherProductCoal),callback:function ($$v) {_vm.$set(_vm.ruleForm, "whetherProductCoal", $$v)},expression:"ruleForm.whetherProductCoal"}},_vm._l((_vm.MKOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1)],1):_vm._e(),(
          (_vm.ruleForm.classes == 10 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 4) ||
          (_vm.ruleForm.classes == 6 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 2)
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"采矿权人","prop":"miningName"}},[_c('el-input',{model:{value:(_vm.ruleForm.miningName),callback:function ($$v) {_vm.$set(_vm.ruleForm, "miningName", $$v)},expression:"ruleForm.miningName"}})],1),_c('el-form-item',{attrs:{"label":"采矿许可证","prop":"miningLicenceUrl"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('el-input',{attrs:{"disabled":""},model:{value:(_vm.originalName),callback:function ($$v) {_vm.originalName=$$v},expression:"originalName"}}),_c('el-upload',{staticClass:"upload-demo",attrs:{"action":"#","on-preview":_vm.handlePreview,"multiple":"","on-exceed":_vm.handleExceed,"file-list":_vm.adjunctList,"http-request":_vm.handleChanges,"show-file-list":false,"accept":".pdf,.PDF"}},[_c('el-button',{attrs:{"size":"small","type":"primary"}},[_vm._v("点击上传")])],1)],1)])],1):_vm._e(),(
          (_vm.ruleForm.classes == 10 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 4) ||
          (_vm.ruleForm.classes == 6 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 2)
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"采矿许可证号","prop":"miningLicenceNo"}},[_c('el-input',{model:{value:(_vm.ruleForm.miningLicenceNo),callback:function ($$v) {_vm.$set(_vm.ruleForm, "miningLicenceNo", $$v)},expression:"ruleForm.miningLicenceNo"}})],1),_c('el-form-item',{attrs:{"label":"证件有效期","prop":"Certificate"}},[_c('el-date-picker',{attrs:{"type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期","value-format":"yyyy-MM-dd"},on:{"change":_vm.CertificateChange},model:{value:(_vm.ruleForm.Certificate),callback:function ($$v) {_vm.$set(_vm.ruleForm, "Certificate", $$v)},expression:"ruleForm.Certificate"}})],1)],1):_vm._e(),(
          (_vm.ruleForm.classes == 10 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 4) ||
          (_vm.ruleForm.classes == 6 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 2)
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"2021年9月之后新增核定产能(万吨)","prop":"beforeYearCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.beforeYearCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "beforeYearCount", $$v)},expression:"ruleForm.beforeYearCount"}})],1),_c('el-form-item',{attrs:{"label":"2023年煤矿电煤签订任务量(万吨/年)","prop":"taskLoad"}},[_c('el-input',{model:{value:(_vm.ruleForm.taskLoad),callback:function ($$v) {_vm.$set(_vm.ruleForm, "taskLoad", $$v)},expression:"ruleForm.taskLoad"}})],1)],1):_vm._e(),(
          (_vm.ruleForm.classes == 10 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 4) ||
          (_vm.ruleForm.classes == 6 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 2)
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"目前总核定产能(万吨/年)","prop":"targetCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.targetCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "targetCount", $$v)},expression:"ruleForm.targetCount"}})],1),_c('el-form-item',{attrs:{"label":"2022年预计产量(万吨)","prop":"predictedOutput"}},[_c('el-input',{model:{value:(_vm.ruleForm.predictedOutput),callback:function ($$v) {_vm.$set(_vm.ruleForm, "predictedOutput", $$v)},expression:"ruleForm.predictedOutput"}})],1)],1):_vm._e(),(
          (_vm.ruleForm.classes == 6 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 2) ||
          (_vm.ruleForm.classes == 10 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 4)
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"2023年计划产量(万吨)","prop":"afterPlantOutput"}},[_c('el-input',{model:{value:(_vm.ruleForm.afterPlantOutput),callback:function ($$v) {_vm.$set(_vm.ruleForm, "afterPlantOutput", $$v)},expression:"ruleForm.afterPlantOutput"}})],1),(
            _vm.ruleForm.classes == 10 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 4
          )?_c('el-form-item',{attrs:{"label":"营业执照","prop":"licenseUrl"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.centerDialogVisible = true}}},[_vm._v("查看")])],1):_vm._e()],1):_vm._e(),(
          _vm.ruleForm.classes == 10 &&
          _vm.ruleForm.classes != '' &&
          _vm.ruleForm.type == 4
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"是否统调电厂","prop":"whetherTracking"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.ruleForm.whetherTracking),callback:function ($$v) {_vm.$set(_vm.ruleForm, "whetherTracking", $$v)},expression:"ruleForm.whetherTracking"}},_vm._l((_vm.TdOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"2022年用煤总量(万吨)","prop":"currentYearCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.currentYearCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "currentYearCount", $$v)},expression:"ruleForm.currentYearCount"}})],1)],1):_vm._e(),(
          _vm.ruleForm.classes == 10 &&
          _vm.ruleForm.classes != '' &&
          _vm.ruleForm.type == 4
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"2023年国内煤炭预测用量(万吨)","prop":"afterYearInternalCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.afterYearInternalCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "afterYearInternalCount", $$v)},expression:"ruleForm.afterYearInternalCount"}})],1),(
            (_vm.ruleForm.classes != 5 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 1) ||
            (_vm.ruleForm.classes == 10 &&
              _vm.ruleForm.classes != '' &&
              _vm.ruleForm.type == 4)
          )?_c('el-form-item',{attrs:{"label":"2023年进口煤炭预测用量(万吨)","prop":"afterYearImportCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.afterYearImportCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "afterYearImportCount", $$v)},expression:"ruleForm.afterYearImportCount"}})],1):_vm._e()],1):_vm._e(),(
          _vm.ruleForm.classes != 5 &&
          _vm.ruleForm.classes != '' &&
          _vm.ruleForm.type == 1
        )?_c('div',{staticClass:"flex"},[(
            _vm.ruleForm.classes != 5 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 1
          )?_c('el-form-item',{attrs:{"label":"所属省市区","prop":"cityId"}},[_c('el-cascader',{attrs:{"options":_vm.citys,"placeholder":"请选择所属省市区","props":{
              value: 'id',
              label: 'name',
            }},on:{"change":_vm.cityIdChange},model:{value:(_vm.ruleForm.cityId),callback:function ($$v) {_vm.$set(_vm.ruleForm, "cityId", $$v)},expression:"ruleForm.cityId"}})],1):_vm._e(),_c('el-form-item',{attrs:{"label":"营业执照","prop":"licenseUrl"}},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.centerDialogVisible = true}}},[_vm._v("查看")])],1)],1):_vm._e(),(
          _vm.ruleForm.classes != 5 &&
          _vm.ruleForm.classes != '' &&
          _vm.ruleForm.type == 1
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"是否统调电厂","prop":"whetherTracking"}},[_c('el-select',{attrs:{"placeholder":"请选择"},model:{value:(_vm.ruleForm.whetherTracking),callback:function ($$v) {_vm.$set(_vm.ruleForm, "whetherTracking", $$v)},expression:"ruleForm.whetherTracking"}},_vm._l((_vm.TdOptions),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}})}),1)],1),_c('el-form-item',{attrs:{"label":"2022年用煤总量(万吨)","prop":"currentYearCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.currentYearCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "currentYearCount", $$v)},expression:"ruleForm.currentYearCount"}})],1)],1):_vm._e(),(
          _vm.ruleForm.classes != 5 &&
          _vm.ruleForm.classes != '' &&
          _vm.ruleForm.type == 1
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"2023年国内煤炭预测用量(万吨)","prop":"afterYearInternalCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.afterYearInternalCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "afterYearInternalCount", $$v)},expression:"ruleForm.afterYearInternalCount"}})],1),(
            _vm.ruleForm.classes != 5 &&
            _vm.ruleForm.classes != '' &&
            _vm.ruleForm.type == 1
          )?_c('el-form-item',{attrs:{"label":"2023年进口煤炭预测用量(万吨)","prop":"afterYearImportCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.afterYearImportCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "afterYearImportCount", $$v)},expression:"ruleForm.afterYearImportCount"}})],1):_vm._e()],1):_vm._e(),(
          _vm.ruleForm.classes == 9 &&
          _vm.ruleForm.classes != '' &&
          _vm.ruleForm.type == 3
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"2022年用煤总量(万吨)","prop":"currentYearCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.currentYearCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "currentYearCount", $$v)},expression:"ruleForm.currentYearCount"}})],1),_c('el-form-item',{attrs:{"label":"2023年国内煤炭预测用量(万吨)","prop":"afterYearInternalCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.afterYearInternalCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "afterYearInternalCount", $$v)},expression:"ruleForm.afterYearInternalCount"}})],1)],1):_vm._e(),(
          _vm.ruleForm.classes == 9 &&
          _vm.ruleForm.classes != '' &&
          _vm.ruleForm.type == 3
        )?_c('div',{staticClass:"flex"},[_c('el-form-item',{attrs:{"label":"2023年进口煤炭预测用量(万吨)","prop":"afterYearImportCount"}},[_c('el-input',{model:{value:(_vm.ruleForm.afterYearImportCount),callback:function ($$v) {_vm.$set(_vm.ruleForm, "afterYearImportCount", $$v)},expression:"ruleForm.afterYearImportCount"}})],1)],1):_vm._e(),_c('div',{staticClass:"btns"},[_c('el-form-item',[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.submitForm('ruleForm')}}},[_vm._v("提交")]),_c('el-button',{on:{"click":function($event){return _vm.resetForm('ruleForm')}}},[_vm._v("关闭")])],1)],1)])],1),_c('el-dialog',{attrs:{"title":"提示","visible":_vm.centerDialogVisible,"width":"30%","center":""},on:{"update:visible":function($event){_vm.centerDialogVisible=$event}}},[_c('div',{staticClass:"diglogImg"},[_c('el-image',{staticStyle:{"width":"200px"},attrs:{"src":_vm.licenseUrl,"preview-src-list":_vm.srcList}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){_vm.centerDialogVisible = false}}},[_vm._v("确 定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }